import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { API_LINK } from "../constants/commonConstants";

const HomeBannerSlider = () => {
  const [Banners, SetBanners] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const LoadBanners = async () => {
    const response = await axios.get(`${API_LINK}/banner/getAllBanners`);
    SetBanners(response.data.data);
  };

  useEffect(() => {
    LoadBanners();
  }, []);

  return (
    <section className="home-banner-section home-banner-slider">
      <Slider {...settings}>
        {Banners.map((item, index) => (
          <div className="home-banner" key={index}>
            <div className="overlay"></div>
            <div className="container BannerDesc">
              <div className="banner-content text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    <h2 className="banner-title">{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="banner-image"
              src={item.imageUrl}
              alt={`Banner ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default HomeBannerSlider;
